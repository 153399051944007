<template>
  <div class="modal fade" id="modal-delete">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Delete</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="form">
          <h5>You'll lose all responses collected in this Practice. We can't recover them once you delete.</h5>
          <h5>Are you sure you want to permanently delete this Practice?</h5>
        </div>
        <div class="modal-footer">
          <button type="button"
                  :disabled="loading"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">Cancel</button>
          <button type="button"
                  @click.prevent="deleteForm()"
                  :disabled="loading"
                  class="btn btn-danger float-end">Yes, Delete It</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      form: null,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-delete'));
  },
  methods: {
    show() {
      this.form = {...this.$store.state.settings.settings}
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    deleteForm() {
      this.loading = true;

      this.axios.delete('/form')
        .then(() => {
          this.$store.commit('form/setForm', null);
          this.$router.push({name: 'forms_add'});
          this.hide();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>