<template>
  <!-- Page-Title -->
  <div class="row">
    <div class="col-sm-12">
      <div class="page-title-box">
        <div class="row">
          <div class="col">
            <h4 class="page-title">{{ title }}</h4>
          </div><!--end col-->
        </div><!--end row-->
      </div><!--end page-title-box-->
    </div><!--end col-->
  </div><!--end row-->
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>