<template>
  <div class="container">
    <div class="row vh-100 d-flex justify-content-center">
      <div class="col-12 align-self-center">
        <div class="row">
          <div class="col-lg-5 mx-auto">
            <div class="card">
              <div class="card-body p-0 auth-header-box">
                <div class="text-center p-3">
                  <a href="#" class="logo logo-admin">
                    <img :src="baseUrl + 'assets/images/logo.png'" height="50" alt="logo" class="auth-logo">
                  </a>
                  <h4 class="mt-3 mb-1 fw-semibold text-white font-18">Let's Get Started The Form</h4>
                </div>
              </div>
              <div class="card-body p-5">
                <p class="text-center" v-if="loading">Please wait...</p>

                <button class="btn btn-danger w-100 waves-effect waves-light"
                        @click.prevent="googleSignIn"
                        v-if="!loading"
                        type="button"><i class="fab fa-google"></i> Sign In With Google</button>
              </div><!--end card-body-->

              <div class="card-body bg-light-alt text-center">
                <span class="text-muted d-none d-sm-inline-block">The Form © 2022</span>
              </div>
            </div><!--end card-->
          </div><!--end col-->
        </div><!--end row-->
      </div><!--end col-->
    </div><!--end row-->
  </div><!--end container-->
</template>

<script>
export default {
  created() {
    if ("code" in this.$route.query) {
      this.loading = true;

      this.$store.dispatch('auth/login', {
        'code': this.$route.query.code
      }).then(() => window.location.href = process.env.VUE_APP_BASE)
        .finally(() => this.loading = false);
    }
  },
  methods: {
    googleSignIn() {
      // Google's OAuth 2.0 endpoint for requesting an access token
      let oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

      // Create <form> element to submit parameters to OAuth 2.0 endpoint.
      let form = document.createElement('form');
      form.setAttribute('method', 'GET'); // Send as a GET request.
      form.setAttribute('action', oauth2Endpoint);

      // Parameters to pass to OAuth 2.0 endpoint.
      let params = {
        'client_id': process.env.VUE_APP_GOOGLE_CLIENT_ID,
        'redirect_uri': process.env.VUE_APP_GOOGLE_REDIRECT_URI,
        'scope': 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.profile email',
        'access_type': 'offline',
        'include_granted_scopes': 'true',
        'response_type': 'code',
        'state': 'google'
      };

      // Add form parameters as hidden input values.
      for (let p in params) {
        let input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', p);
        input.setAttribute('value', params[p]);
        form.appendChild(input);
      }

      // Add form to page and submit it to open the OAuth 2.0 endpoint.
      document.body.appendChild(form);
      form.submit();
    },
  }
}
</script>