<template>
  <page-title-component title="Settings"></page-title-component>

  <div class="row">
    <div class="col-12 text-end">
      <button class="btn btn-sm btn-success"
              @click="$refs.addNewFormModal.show()">Add New</button>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-center"
           v-if="loading">
        <div class="spinner-border text-primary" role="status"></div>
      </div>

      <div class="table-responsive"
           v-else>
        <table class="table">
          <thead>
          <tr>
            <th>Name</th>
            <th class="text-end">Action</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="item in items" :key="'item_form_'+item.id">
            <td>
              <img src="/images/icons/Google_Forms_logo_(2014-2020).svg"
                   height="30"
                   class="me-2" />
              {{ item.google_form_name }}

              <span class="text-info fw-bold" v-if="item.default">(Default)</span>
            </td>

            <td class="text-end">
              <a class="btn btn-sm btn-info me-1"
                 @click.prevent="changeDefaultNote(item.id)"
                 v-if="!item.default && item.type === 'note'">
                <i class="fas fa-check"></i>
              </a>

              <a class="btn btn-sm btn-primary me-1"
                 target="_blank"
                 :href="item.google_form_link+'/viewform'">
                <i class="fas fa-eye"></i>
              </a>

              <a class="btn btn-sm btn-info"
                 target="_blank"
                 :href="item.google_form_link">
                <i class="fas fa-edit"></i>
              </a>
            </td>
          </tr>
          <tr v-for="item in items" :key="'item_doc_'+item.id">
            <td>
              <img src="/images/icons/Google_Docs_logo_(2014-2020).svg"
                   height="30"
                   class="me-2" />
              {{ item.google_template_name }}
            </td>

            <td class="text-end">
              <a class="btn btn-sm btn-info"
                 target="_blank"
                 :href="item.google_template_link">
                <i class="fas fa-edit"></i>
              </a>
            </td>
          </tr>
          <tr v-for="item in items" :key="'item_sheet_'+item.id">
            <td>
              <img src="/images/icons/Google_Sheets_logo_(2014-2020).svg"
                   height="30"
                   class="me-2" />
              {{ item.google_response_name }}
            </td>

            <td class="text-end">
              <a class="btn btn-sm btn-info"
                 target="_blank"
                 :href="item.google_response_link">
                <i class="fas fa-edit"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <delete-modal ref="deleteModal" />
      <button class="btn btn-danger me-3" @click="$refs.deleteModal.show()">
        Delete Your Practice Installation Here
      </button>

    </div>
  </div>

  <add-new-form-modal @added="loadFormItems" ref="addNewFormModal" />
</template>

<script>
import DeleteModal from "@/components/DeleteModal";
import PageTitleComponent from "@/components/PageTitleComponent";
import AddNewFormModal from "@/components/AddNewFormModal.vue";
export default {
  components: { PageTitleComponent, DeleteModal, AddNewFormModal },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.loadFormItems();
  },
  methods: {
    loadFormItems() {
      this.loading = true;

      this.axios.get("/form-items").then((response) => {
          this.items = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeDefaultNote(formItemId) {
      this.axios.post('/default/note', {
          form_item_id: formItemId
        })
        .then(() => {
          this.loadFormItems();
        })
    }
  }
};
</script>