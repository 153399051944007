<template>
  <div class="modal fade" id="add-new-form-modal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add New</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>Type</label>

            <select :class="['form-select', {'is-invalid': errorFor('type')}]"
                    :disabled="loading"
                    v-model="form.type">
              <option value="">Select Type</option>
              <option value="intake">Intake</option>
              <option value="note">Note</option>
            </select>
            <v-errors :errors="errorFor('type')" />
          </div>

          <div class="form-group">
            <label>Form</label>

            <select :class="['form-select', {'is-invalid': errorFor('form_id')}]"
                    :disabled="loading"
                    @change="changeForm"
                    v-model="form.form_id">
              <option value="">Select Form</option>
              <option :value="form.id"
                      v-for="form in forms"
                      :key="'form_'+form.id">{{ form.name }}</option>
            </select>
            <v-errors :errors="errorFor('form_id')" />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button"
                  :disabled="loading"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">Cancel</button>
          <button type="button"
                  @click.prevent="submit"
                  v-if="!loading"
                  class="btn btn-success float-end">Add</button>

          <button class="btn btn-success float-end"
                  v-else
                  type="button"
                  disabled="">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      noteForms: [],
      intakeForms: [],
      modal: null,
      form: {
        type: '',
        form_id: '',
        template_id: '',
      }
    }
  },
  computed: {
    forms() {
      if (this.form.type === 'intake')
        return this.intakeForms;
      else if (this.form.type === 'note')
        return this.noteForms;
      else
        return [];
    }
  },
  created() {
    this.loadForms()
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-new-form-modal'));
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/add-new-form', this.form)
        .then(() => {
          this.$emit('added');
          this.hide();
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeForm() {
      if (this.form.form_id) {
        if (this.form.type === 'intake') {
          this.form.template_id = this.intakeForms.find(f => f.id == this.form.form_id).template_id;
        } else if (this.form.type === 'note') {
          this.form.template_id = this.noteForms.find(f => f.id == this.form.form_id).template_id;
        } else {
          this.form.template_id = null;
        }
      } else {
        this.form.template_id = null;
      }
    },
    loadForms() {
      this.axios.get('/intake-forms')
        .then(response => {
          response.data.values.forEach((v, i) => {
            if (i > 0) {
              this.intakeForms.push({
                id: v[0],
                name: v[1],
                template_id: v[2],
                template_name: v[3],
              })
            }
          })
        });

      this.axios.get('/note-forms')
        .then(response => {
          response.data.values.forEach((v, i) => {
            if (i > 0) {
              this.noteForms.push({
                id: v[0],
                name: v[1],
                template_id: v[2],
                template_name: v[3],
              })
            }
          })
        });
    }
  }
}
</script>